import React, { lazy, Suspense } from "react";

const LazyObjectNativeIntegration = lazy(
  () => import("./native-integration/object-native-integration")
);

export function ObjectNativeIntegration(props) {
  return (
    <Suspense fallback={null}>
      <LazyObjectNativeIntegration {...props} />
    </Suspense>
  );
}

const LazyWorkflowManager = lazy(
  () => import("./native-integration/workflow-manager/workflow-manager")
);

export function WorkflowManager(props) {
  return (
    <Suspense fallback={null}>
      <LazyWorkflowManager {...props} />
    </Suspense>
  );
}

const LazyCustomIntegrationSetupPage = lazy(
  () => import("./custom-integration/index")
);

export function CustomIntegrationSetupPage(props) {
  return (
    <Suspense fallback={null}>
      <LazyCustomIntegrationSetupPage {...props} />
    </Suspense>
  );
}

const LazyDocumentCloudProviders = lazy(
  () => import("./setup/cloud-providers/document-cloud-providers-form")
);

export function DocumentCloudProviders(props) {
  return (
    <Suspense fallback={null}>
      <LazyDocumentCloudProviders {...props} />
    </Suspense>
  );
}
